<template lang="pug">
section.products-collections
  .container.framed
    .products-collections__grid
      nuxt-link(
        v-for="i, k in collections"
        v-image="i.image"
        :style="{ backgroundColor: i.accent }"
        :data-slug="i.slug"
        :title="i.title"
        :to="i.to"
        :key="k"
        @click.native="$amplitude.track('HOMEPAGE_COLLECTION_VIEW', { slug: i.slug })")
        h3(v-text="i.title")
        span See all &gt;
</template>

<script>
export default {
  data () {
    return {
      collections: [
        // {
        //   title: 'Shop Local',
        //   slug: 'local',
        //   image: '/img/collections/local.webp',
        //   accent: '#5384b8',
        //   to: {
        //     name: 'products'
        //   }
        // },
        {
          title: 'Shop New',
          slug: 'new',
          image: '/img/collections/new.webp',
          accent: '#9d62c6',
          to: {
            name: 'products',
            query: {
              category_id: 0
            }
          }
        },
        // {
        //   title: 'Shop Sustainable',
        //   slug: 'sustainable',
        //   image: '/img/collections/sustainable.webp',
        //   accent: '#3cc0ea',
        //   to: {
        //     name: 'products'
        //   }
        // },
        {
          title: 'Shop Popular',
          slug: 'popular',
          image: '/img/collections/popular.webp',
          accent: '#cc6aaf',
          to: {
            name: 'products'
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.products-collections {
  margin: 96px 0;
  &__grid {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    @include media(md) {
      gap: 24px;
      // grid-template-columns: 2fr 2fr 2fr 3fr;
      grid-template-columns: 1fr 1fr;
    }
    > a {
      min-width: 0;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      border-radius: 24px;
      position: relative;
      box-sizing: border-box;
      background-clip: border-box;
      // background-position: top left;
      // aspect-ratio: 1;
      height: 220px;
      background-position: center;
      padding: 20px;
      @include media(sm) {
        padding: 28px 24px;
      }
      &:last-child {
        aspect-ratio: auto;
      }
      &:before {
        content: '';
        border: 2px solid rgba(white, .5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 24px;
        pointer-events: none;
      }
      h3 {
        font-size: 20px;
        line-height: 120%;
        margin: 0 0 8px 0;
        font-weight: $--font-weight-normal;
      }
      span {
        line-height: 120%;
        @include media(sm-down) {
          display: none;
        }
      }
    }
  }
}
</style>
