import { render, staticRenderFns } from "./Bloomberg2024Section.vue?vue&type=template&id=6d59c55c&scoped=true&lang=pug&"
import script from "./Bloomberg2024Section.vue?vue&type=script&lang=js&"
export * from "./Bloomberg2024Section.vue?vue&type=script&lang=js&"
import style0 from "./Bloomberg2024Section.vue?vue&type=style&index=0&id=6d59c55c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d59c55c",
  null
  
)

export default component.exports