<template lang="pug">
section.hero.home-section
  .container.framed
    h1.hero__title
      //- rolling-text.hero__headline(:text="headlines")
      typing-text.hero__headline(:texts="headlines") Innovative
      br
      span Wholesale distribution

    el-row(:gutter="48")
      el-col(:sm="8")
        .hero__video
          p Discover and procure from an unparalleled catalog of brands with the industry's fastest speed to shelf.
          //- .hero__video-thumbnail(@click.prevent="video = true")

          nuxt-link.el-button.el-button--highlight(
            v-if="!$store.getters['authentication/isLogged']"
            :to="{ name: 'register' }"
            @click.native="$amplitude.track('HOMEPAGE_REGISTER', { zone: 'hero-section' })"
            title="Sign up") Sign up

          //- .hero__video-actions
            nuxt-link.cfa.el-button.el-button--primary(
              v-if="!logged"
              title="Learn more about our services"
              :to="{ name: 'services-brands' }") Brands
            nuxt-link.cfa.el-button.button-retailers(
              v-if="!logged"
              title="Learn more about our services"
              :to="{ name: 'services-retailers' }") Retailers

      el-col(:sm="16")
        .hero__figure
          .hero__bars(style="animation-delay: 0")
            .hero__bars-title Total Monthly Sales
            .hero__bars-chart
              div(style="height: 12%")
              div(style="height: 24%")
              div(style="height: 23%")
              div(style="height: 27%")
              div(style="height: 28%")
              div(style="height: 34%")
              div(style="height: 32%")
              div(style="height: 60%")
              div(style="height: 68%")
              div(style="height: 70%")
              div(style="height: 89%")
              div(style="height: 100%")

          .hero__brand.hero__floating(style="animation-delay: 5s")
            img.hero__brand-avatar(src="/img/home/icon.brand.svg" width="64" height="64" alt="Analytics")
            .hero__brand-title Analytics
            .hero__brand-metas
              div Total Orders
                strong 1,624
              div Cases Sold
                strong 848

          .hero__stamp.hero__stamp--sales.hero__floating(style="animation-delay: -2s")
            .hero__stamp-icon
              img(src="/img/home/icon.sales.webp" width="64" height="64" alt="Sales")
            .hero__stamp-info
              .hero__stamp-key Total Sales
              .hero__stamp-value $6,329,200

          .hero__stamp.hero__stamp--location.hero__floating(style="animation-delay: 3s")
            .hero__stamp-icon
              img(src="/img/home/icon.location.webp" width="64" height="64" alt="Location")
            .hero__stamp-info
              .hero__stamp-key Location
              .hero__stamp-value
                rolling-text(:texts="locations" :placeholder="locations[0]")

  //- el-dialog.video-player(
    :visible.sync="video"
    append-to-body
    destroy-on-close)
    .player(v-if="video")
      iframe(
        src="https://player.vimeo.com/video/497691544?autoplay=1&color=b02386&title=0&byline=0&portrait=0"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen)
      script(src="https://player.vimeo.com/api/player.js")
</template>

<script>
import { mapGetters } from 'vuex'
import TypingText from '~/components/TypingText'
import RollingText from '~/components/RollingText'

export default {
  components: {
    TypingText,
    RollingText
  },

  data () {
    return {
      video: false,
      headlines: [{
        text: 'Innovative',
        style: { color: '#CA1A96' }
      }, {
        text: 'Transparent',
        style: { color: '#01012A' }
      }, {
        text: 'No deductions',
        style: { color: '#8C2BBA' }
      }, {
        text: 'Intelligent',
        style: { color: '#49A8FF' }
      }, {
        text: 'Consolidated',
        style: { color: '#FDC025' }
      }, {
        text: 'Strategic',
        style: { color: '#F76C01' }
      }],
      locations: [
        'Chicago, IL',
        'New York, NY',
        'San Francisco, CA',
        'Oakland, CA',
        'Los Angeles, CA',
        'Santa Monica, CA',
        'Boston, MA'
      ]
    }
  },

  computed: {
    ...mapGetters({
      logged: 'authentication/logged'
    })
  }
}
</script>

<style lang="scss" scoped>
// .video-player {
//   background: rgba(black, .9);
//   ::v-deep {
//     .el-dialog {
//       position: relative;
//       background: black;
//       width: 1024px;
//       margin: 0 auto;
//       max-width: 100%;
//       &__header {
//         padding: 0;
//       }
//       &__close {
//         position: fixed;
//         color: white;
//         border-radius: 100%;
//         z-index: 1;
//         top: 1em;
//         right: 1em;
//         font-size: 3rem;
//       }
//       &__body {
//         padding: 0;
//       }
//     }
//     .player {
//       padding-top: 56.25%;
//       position: relative;
//       width: 100%;
//       height: 0;
//       font-size: 0;
//       > iframe {
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//       }
//     }
//   }
// }

@keyframes caret {
  0% { border-color: transparent }
  50% { border-color: $--color-text-regular }
}
@keyframes rotate {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
@keyframes float {
  0% { transform: translateY(0px) }
  50% { transform: translateY(-12px) }
  100% { transform: translateY(0px) }
}
@keyframes grow {
  0% { transform: scaleY(1) }
  50% { transform: scaleY(1.1) }
  100% { transform: scaleY(1) }
}
.hero {
  position: relative;
  z-index: 1;
  padding: calc(clamp(44px, 9vw, 96px) * 1.35) 0;
  overflow: hidden;
  &__title {
    text-align: center;
    font-size: clamp(44px, 9vw, 96px);
    line-height: 110%;
    color: #01012A;
    margin: 0 0 1em;
    font-weight: normal;
  }
  &__headline {
    // border-right: .05em solid $--color-text-regular;
    // animation: caret .75s step-end infinite;
    font-weight: $--font-weight-bold;
  }
  &__video {
    @include media(md) {
      max-width: 280px;
    }
    p {
      font-size: clamp(16px, 2vw, 22px);
      line-height: 150%;
    }
    .el-button {
      display: block;
      width: 100%;
      margin: 24px 0 0 0;
    }
    // &-thumbnail {
    //   border-radius: 24px;
    //   width: 100%;
    //   height: 0;
    //   padding-bottom: 55%;
    //   background-color: $--color-placeholder;
    //   background-image: url(/img/card.video.webp);
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   position: relative;
    //   cursor: pointer;
    //   &:hover {
    //     &:before {
    //       transform: scale(1.2);
    //     }
    //   }
    //   &:before {
    //     content: '';
    //     width: 56px;
    //     height: 56px;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     margin: -28px 0 0 -28px;
    //     border-radius: 100%;
    //     transform: scale(1);
    //     transition: all .3s ease-in-out;
    //     background-color: rgba(white, .5);
    //     backdrop-filter: blur(16px);
    //     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAdCAYAAACqhkzFAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC2SURBVHgBrZbBEcIwDARlKqAESqAFOqITSoFS6EDpADpY7MmDZGInsuSd0SePG98msS3AI89ZRsGM5rnICPjzyXOXKGyJKaCOuhXQxqeAY/oUYEPNCrBjU0A/+wrwobQU4KeugDgrBak8kThTnltKaRoVWHiX0JOM4SXzCr8jHK5fDH40z3WzVnw8aX3c9LP/+3UEKbWKzsB2RUdg3yZLtKIx0F7REBg7SsMVG4H+ipXA+G1hwQ+ZXsxxPMAQZgAAAABJRU5ErkJggg==);
    //     background-repeat: no-repeat;
    //     background-position: center left 22px;
    //     background-size: 16px;
    //   }
    // }
  }
  &__figure {
    border: 2px solid white;
    background: rgba(white, .4);
    border-radius: 24px;
    width: 700px;
    height: 400px;
    position: relative;
    z-index: 1;
    margin: 48px -5%;
    zoom: .7;
    @include media(sm) {
      zoom: .8;
      margin: 0;
    }
    @include media(md) {
      zoom: 1;
    }
    &:after {
      content: '';
      z-index: -1;
      position: absolute;
      background: rgba(#842bba, .15);
      top: 0;
      right: 10%;
      bottom: 0;
      left: 10%;
      border-radius: 100%;
      filter: blur(48px);
    }
  }
  &__brand {
    background: white;
    border-radius: 16px;
    padding: 24px;
    width: 200px;
    text-align: center;
    position: absolute;
    top: 20%;
    left: 10%;
    &-title {
      font-size: 16px;
      font-weight: $--font-weight-bold;
      margin: 16px 0 24px;
    }
    &-metas {
      display: flex;
      > * {
        min-width: 0;
        flex: 1;
        font-size: 10px;
        color: $--color-text-secondary;
        strong {
          font-weight: $--font-weight-bold;
          color: $--color-text-regular;
          display: block;
          font-size: 16px;
        }
      }
    }
  }
  &__floating {
    animation: float 4s ease-in-out infinite;
  }
  &__bars {
    width: 480px;
    padding: 24px;
    background: rgba(white, .4);
    border-radius: 16px;
    border: 2px solid white;
    position: absolute;
    top: 10%;
    right: -12%;
    backdrop-filter: blur(8px);
    &-title {
      font-weight: $--font-weight-bold;
      font-size: 16px;
      margin: 0 0 16px;
    }
    &-chart {
      display: flex;
      padding: 0 2%;
      gap: 4%;
      align-items: flex-end;
      height: 180px;
      background: url(/img/home/rows.svg);
      background-size: contain;
      background-repeat: repeat-x;
      > * {
        min-width: 0;
        flex: 1;
        background: lighten($--color-primary, 20);
        border-radius: 6px 6px 0 0;
        position: relative;
        overflow: hidden;
        transform-origin: bottom center;
        animation: grow 4s ease-in-out infinite;
        @for $i from 1 through 12 {
          &:nth-child(#{$i}) {
            animation-delay: #{calc(random(100) / 100 * 4) + 's'};
          }
        }
        &:before, &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: #{25 + random(15) + '%'};
        }
        &:before {
          top: 0;
          background: lighten($--color-primary, 35);
        }
        &:after {
          bottom: 0;
          background: $--color-primary;
        }
      }
    }
  }
  &__stamp {
    display: flex;
    background: rgba(white, .4);
    align-items: center;
    gap: 8px;
    padding: 24px;
    min-width: 240px;
    border-radius: 16px;
    border: 2px solid white;
    position: relative;
    backdrop-filter: blur(8px);
    &--location {
      position: absolute;
      bottom: -20%;
      left: 35%;
      &:before {
        content: '';
        background: url(/img/home/character.guy.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 96px;
        height: 120px;
        top: -100%;
        left: 0;
        pointer-events: none;
        position: absolute;
      }
    }
    &--sales {
      position: absolute;
      left: -5%;
      bottom: -8%;
      &:before {
        content: '';
        background: url(/img/home/character.girl.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 96px;
        height: 120px;
        top: -100%;
        left: 8%;
        pointer-events: none;
        position: absolute;
      }
    }
    > * {
      min-width: 0;
    }
    &-icon {
      flex: 0 64px;
    }
    &-info {
      flex: 1;
    }
    &-key {
      font-size: 12px;
      line-height: 20px;
      color: $--color-text-secondary;
    }
    &-value {
      font-size: 16px;
      line-height: 28px;
      font-weight: $--font-weight-bold;
    }
  }
}
</style>
